import { createEvent } from './utils';

const addDatalayer = (datalayerOutcomesConfig) => {
    const datalayerName = datalayerOutcomesConfig.datalayerName

    if (!(datalayerName in window)) window[datalayerName] = [];

    const pageView = {};
    const events = [];

    if (datalayerOutcomesConfig.includeOutcomes && window.Zephr.outcomes) {
        pageView.zephrOutcomes = window.Zephr.outcomes;

        if (datalayerOutcomesConfig.outcomesAsEvents) {
            Object.keys(window.Zephr.outcomes || []).forEach(function (outcomeKey) {
                const outcomeEvent = {
                    event: "zephr-outcome-" + outcomeKey,
                    featureId: outcomeKey,
                    featureLabel: window.Zephr.outcomes[outcomeKey].featureLabel,
                    outcomeId: window.Zephr.outcomes[outcomeKey].outcomeId,
                    outcomeLabel: window.Zephr.outcomes[outcomeKey].outcomeLabel,
                };
                events.push(outcomeEvent);
            });
        }
    }
    if (window.Zephr && window.Zephr.accessDetails) {
        Object.keys((window.Zephr.accessDetails.trials || {})).forEach(function (trialId) {
            const trial = window.Zephr.accessDetails.trials[trialId]
            if (trial.reportInDataLayer) {
                const usedCredits = trial.totalCredits - trial.remainingCredits
                if (datalayerOutcomesConfig.groupFields && !pageView.zephrTrials) pageView.zephrTrials = {};
                const target = datalayerOutcomesConfig.groupFields ? pageView.zephrTrials : pageView
                if (trial.dataLayerCreditsUsedKey) {
                    target[trial.dataLayerCreditsUsedKey] = usedCredits;
                }
                if (trial.dataLayerCreditsRemainingKey) {
                    target[trial.dataLayerCreditsRemainingKey] = trial.remainingCredits;
                }
            }
        });
        (window.Zephr.accessDetails.trialTrackingDetails || []).forEach(function (details) {
            const type = details.entitlementType === 'credits' ? 'credits' : 'meters';
            const trial = (window.Zephr.accessDetails[type] || {})[details.entitlementId];
            if (!trial) return;
            if (datalayerOutcomesConfig.groupFields && !pageView.zephrTrials) pageView.zephrTrials = {};
            if (details.creditsRemainingKey) {
                if (datalayerOutcomesConfig.groupFields) {
                    pageView.zephrTrials[details.creditsRemainingKey] = trial.remainingCredits;
                } else {
                    pageView[details.creditsRemainingKey] = trial.remainingCredits;
                }
            }
            if (details.creditsUsedKey) {
                if (datalayerOutcomesConfig.groupFields) {
                    pageView.zephrTrials[details.creditsUsedKey] = trial.totalCredits - trial.remainingCredits;
                } else {
                    pageView[details.creditsUsedKey] = trial.totalCredits - trial.remainingCredits;
                }
            }
        });
    }
    if (Object.keys(pageView).length) {
        pageView.event = "zephr-pageview";
        events.unshift(pageView);
    }

    if (events.length) {
        events.forEach(function (event) {
            window[datalayerName].push(event);
        });

        document.dispatchEvent(createEvent('zephr.dataLayerReady'));
    }
}

export {
    addDatalayer,
};