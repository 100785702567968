import { truncateNodeLegacy, truncateNodeLocaleAware } from './outcomeNodes';
import { DEFAULT_FLAGS, isIntlSegmenterSupported } from './utils';

const addOutcomeTrackerScript = (outcome) => {
  if (!window.Zephr) window.Zephr = {};
  if (!window.Zephr.outcomes) window.Zephr.outcomes = {};

  window.Zephr.outcomes[outcome.featureId] = {
    featureLabel: outcome.featureLabel,
    outcomeId: outcome.outcomeId,
    outcomeLabel: outcome.outcomeLabel,
  };
};

const parseFromString = (string) => {
  const parsedHtml = new DOMParser().parseFromString(string, 'text/html');
  // Use childNodes instead of children to capture all nodes including text nodes
  const parsedNodes = [...Array.from(parsedHtml.head.childNodes), ...Array.from(parsedHtml.body.childNodes)];
  if (!parsedNodes.length) {
    return document.createTextNode(string);
  }

  const fragment = document.createDocumentFragment();
  parsedNodes.forEach((parsedNode) => fragment.appendChild(parsedNode));

  executeScriptTags(fragment);

  return fragment;
}

const executeScriptTags = (documentFragment) => {
  const tmpScripts = documentFragment.querySelectorAll('script');
  if (!tmpScripts.length) return;

  const scripts = Array.from(tmpScripts);
  scripts.map((script) => {
    const newScript = document.createElement('script');
    Array.from(script.attributes).forEach((attr) => {
      newScript.setAttribute(attr.name, attr.value);
    });
    newScript.innerHTML = script.innerHTML;
    script.parentNode.appendChild(newScript);
    script.parentNode.removeChild(script);
  });
};

const injectForm = (outcome, resources) => {
  if (!resources.forms || !resources.forms[outcome.formId]) {
    console.error('Form not found.');
    return;
  }

  return parseFromString(resources.forms[outcome.formId]);
};

const injectPaymentForm = (outcome, resources) => {
  if (!resources.paymentForms || !resources.paymentForms[outcome.formId]) {
    console.error('Payment form not found.');
    return;
  }

  return parseFromString(resources.paymentForms[outcome.formId]);
};

const injectUIComponent = (outcome, resources) => {
  if (!resources.uiComponents || !resources.uiComponents[outcome.componentId]) {
    console.error('UI component not found.');
    return;
  }

  return parseFromString(resources.uiComponents[outcome.componentId]);
};

const injectZone = (id, contents, resources) => {
  if (!resources.uiComponents || !resources.uiComponents[id]) {
    console.error('Zone not found.');
    return;
  }

  const zone = parseFromString(resources.uiComponents[id]);
  const zoneElement = zone.getElementById(`zephr-zone-${id.toLowerCase()}`);
  contents.forEach(c => zoneElement.appendChild(c));
  return zone;
};

const injectHostedUIComponent = (outcome, resources) => {
  if (!resources.hostedUiComponents || !resources.hostedUiComponents[outcome.url]) {
    console.error('Hosted UI component not found.');
    return;
  }

  return parseFromString(resources.hostedUiComponents[outcome.url]);
};

const injectComponentTemplate = (outcome, resources) => {
  if (!resources.componentTemplates || !resources.componentTemplates[outcome.componentId]) {
    console.error('Component template not found.');
    return;
  }

  return parseFromString(resources.componentTemplates[outcome.componentId]);
};

const getOutcomeNode = (originalNode, outcome, resources, flags) => {
  switch(outcome.type) {
    case 'LeavePristine':
      return originalNode;
    case 'Truncate':
      return (flags.LOCALE_AWARE_TRUNCATION && isIntlSegmenterSupported())
        ? truncateNodeLocaleAware(originalNode, outcome)
        : truncateNodeLegacy(originalNode, outcome);
    case 'OutcomeTracker':
      addOutcomeTrackerScript(outcome);
      break;
    case 'Form':
      return injectForm(outcome, resources);
    case 'PaymentForm':
      return injectPaymentForm(outcome, resources);
    case 'UIComponent':
      return injectUIComponent(outcome, resources);
    case 'HostedUIComponent':
      return injectHostedUIComponent(outcome, resources);
    case 'ComponentTemplate':
      return injectComponentTemplate(outcome, resources);
    case 'Remove':
      break;
    case 'Zone':
      const contents = outcome.contents.map(c => getOutcomeNode(originalNode, c, resources, flags));
      return injectZone(outcome.id, contents, resources);
    default:
      console.error(`No matching outcome type ${outcome.type}`);
  }
};

/**
 * Applies the outcomes of a feature decision to an array of DOM nodes
 *
 * @param {NodeList} featureNodes - The DOM nodes the outcomes should be applied to.
 * @param {Array} outcomes - The outcomes of the decision.
 * @param {Object} resources - The resources referenced in outcomes such as forms, UI components, etc.
 * @param {FeatureDecisionFlags} flags - The flags returned by a feature decision.
 */
const applyDecisionOutcomes = (
  featureNodes,
  outcomes,
  resources = {},
  flags = DEFAULT_FLAGS,
) => {
  featureNodes.forEach((node) => {
    const parentNode = node.parentNode;
    const nodeIndex = Array.from(parentNode.children).indexOf(node);

    const isContentGate = node.id === "content-gate"; // FSM-141
    const leavePristine = !!outcomes.find((outcome) => outcome.type === "LeavePristine"); // FSM-141
    const originalNode = isContentGate && leavePristine ? node : parentNode.removeChild(node); // FSM-141

    const outcomeNodes = outcomes
      .filter((outcome) => outcome.type !== "LeavePristine") // FSM-141
      .map((outcome) => getOutcomeNode(originalNode, outcome, resources, flags))
      .filter(Boolean);

    const outcomeFragment = document.createDocumentFragment();
    outcomeNodes.forEach((outcomeNode) => outcomeFragment.appendChild(outcomeNode));

    (nodeIndex < parentNode.children.length)
      ? parentNode.insertBefore(outcomeFragment, parentNode.children[nodeIndex])
      : parentNode.appendChild(outcomeFragment);
  });
};

export {
  applyDecisionOutcomes,
};
