const blaizeScope = {
  transform: {
    concat: (outcomes) => outcomes.map(outcome => evaluateDSL(outcome)),
    zoned: (zone) => ({
      type: 'Zone', 
      id: zone.zoneId,
      contents: zone.transformations.map(outcome => evaluateDSL(outcome))
    }),
    form: (formId) => ({
      type: 'Form', formId,
    }),
    paymentForm: (formId) => ({
      type: 'PaymentForm', formId,
    }),
    resource: (componentId) => ({
      type: 'UIComponent', componentId,
    }),
    parameterisedResource: (componentId) => ({
      type: 'UIComponent', componentId,
    }),
    url: (url) => ({
      type: 'HostedUIComponent', url,
    }),
    componentTemplate: (componentId) => ({
      type: 'ComponentTemplate', componentId,
    }),
    parameterisedComponentTemplate: (componentId) => ({
      type: 'ComponentTemplate', componentId,
    }),
    truncate: (len) => ({
      type: 'Truncate', truncateLength: Number(len), style: 'nostyle'
    }),
    truncateWithStyle: (len, style) => ({
      type: 'Truncate', truncateLength: Number(len), style,
    }),
    outcomeTracker: (featureId, featureLabel, outcomeId, outcomeLabel) => ({
      type: 'OutcomeTracker', featureId, featureLabel, outcomeId, outcomeLabel,
    }),
    remove: () => ({
      type: 'Remove',
    }),
  }
};
const leavePristineScope = { type: 'LeavePristine' };

const evaluateDSL = (decision) => {
  const decisionDSL = !decision.trim().length ? 'blaize.transform.remove()' : decision;
  return new Function('blaize,leave_pristine', `"use strict";return (${decisionDSL});`)(
    blaizeScope,
    leavePristineScope,
  );
};

const parseDecisionDSL = (decision) => {
  return [evaluateDSL(decision)].flat();
};

export {
  parseDecisionDSL,
};
