import { parseDecisionDSL } from './dsl';
import { applyDecisionOutcomes } from './outcomes';
import { addDatalayer } from './datalayer';
import {
  DEFAULT_FLAGS,
  executeScript,
  isIntlSegmenterSupported
} from './utils';

class Zephr {
  cdnApi;
  fetcher;

  constructor(cdnApi, fetcher) {
    this.cdnApi = cdnApi || '';
    this.fetcher = fetcher || null;
  }

  async fetchLiveFeatures() {
    try {
      return await this._fetcher(`${this.cdnApi}/zephr/features`, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      })
        .then(response => response.json());
    } catch (err) {
      return Promise.reject(new Error('Live features endpoint failed.'));
    }
  }

  async fetchDecisions(features, { jwt, customData = {} } = { customData: {} }) {
    const featureIds = features.map((feature) => feature.id);
    try {
      return await this._fetcher(`${this.cdnApi}/zephr/feature-decisions`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...(jwt && {
            'Authorization': `Bearer ${jwt}`
          }),
        },
        body: JSON.stringify({
          path: document.location.pathname + document.location.search + document.location.hash,
          referer: document.referrer,
          featureIds,
          customData,
        })
      })
        .then((response) => response.json());
    } catch (err) {
      return Promise.reject(new Error('Feature decisions endpoint failed.'));
    }
  }

  async executeDecisions(features, decisions, datalayerOutcomesConfig, decisionOptions) {
    for (const feature of features) {
      const decision = (decisions.featureResults || {})[feature.id];
      if (!decision) continue;

      const outcomes = parseDecisionDSL(decision);
      const featureNodes = this.selectFeatureNodes(feature);

      const hasTruncation = outcomes.some(outcome => outcome.type === 'Truncate');
      if (decisionOptions.automaticPolyfills && hasTruncation && !isIntlSegmenterSupported()) {
        await executeScript(SEGMENTER_POLYFILL_URL);
      }

      applyDecisionOutcomes(
        featureNodes,
        outcomes,
        decisions.resources || {},
        this._mergeDefaultFlags(decisions.flags),
      );
    }

    if (decisions.accessDetails) {
      if (!window.Zephr) window.Zephr = {};
      if (!window.Zephr.accessDetails) {
        window.Zephr.accessDetails = decisions.accessDetails;
      } else {
        window.Zephr.accessDetails = this._mergeAccessDetails(window.Zephr.accessDetails, decisions.accessDetails)
      }
    }

    if (datalayerOutcomesConfig) {
      addDatalayer(datalayerOutcomesConfig)
    }
  }

  _fetcher(url, options) {
    if (this.fetcher) {
      return this.fetcher(url, options);
    } else {
      return window.fetch(url, options);
    }
  }

  _mergeAccessDetails(acc1, acc2) {
    return {
      ...acc1,
      ...acc2,
      authenticated: acc2.authenticated,
      accessDecisions: { ...acc1.accessDecisions, ...acc2.accessDecisions },
      entitlements: this._mergeCreditData(acc1.entitlements, acc2.entitlements),
      credits: this._mergeCreditData(acc1.credits, acc2.credits),
      meters: this._mergeCreditData(acc1.meters, acc2.meters),
      trials: this._mergeCreditData(acc1.trials ?? {}, acc2.trials ?? {}),
    };
  }

  _mergeCreditData(meters1, meters2) {
    var combined = { ...meters1, ...meters2 }
    for (const [key, value1] of Object.entries(meters1)) {
      const value2 = meters2[key]
      if (value2) {
        const combinedValue = combined[key];
        if (this._eitherHasProperty(value1, value2, "decrementedInDecision")) {
          combinedValue.decrementedInDecision = !!(value1.decrementedInDecision || value2.decrementedInDecision)
        }
        if (this._eitherHasProperty(value1, value2, "usedInDecision")) {
          combinedValue.usedInDecision = !!(value1.usedInDecision || value2.usedInDecision)
        }
        if (this._eitherHasProperty(value1, value2, "remainingCredits")) {
          combinedValue.remainingCredits = this._minOrNumber(value1.remainingCredits, value2.remainingCredits)
        }
        if (this._eitherHasProperty(value1, value2, "totalCredits")) {
          combinedValue.totalCredits = this._minOrNumber(value1.totalCredits, value2.totalCredits)
        }
      }
    }
    return combined
  }

  _eitherHasProperty(obj1, obj2, property) {
    return (obj1 && obj1.hasOwnProperty(property)) || (obj2 && obj2.hasOwnProperty(property))
  }

  _minOrNumber(value1, value2) {
    const isValue1Number = typeof value1 === 'number';
    const isValue2Number = typeof value2 === 'number';
    if (isValue1Number && isValue2Number) {
      return Math.min(value1, value2)
    } else if (isValue1Number) {
      return value1
    } else if (isValue2Number) {
      return value2
    } else {
      return undefined
    }
  }

  /**
   * Merges the feature decision's flags with a map of defaults.
   *
   * @param {FeatureDecisionFlags} flags - The feature decision flags to merge with.
   *
   * @returns {FeatureDecisionFlags} The merged flags.
   */
  _mergeDefaultFlags(flags = {}) {
    return { ...DEFAULT_FLAGS, ...flags };
  }

  findFeatures(features) {
    if (!Array.isArray(features) || !features.length) return [];

    return features.filter((feature) => {
      if (feature.targetType === 'COMMENT_TAG') return false;

      return document.querySelector(feature.cssSelector) !== null;
    });
  }

  selectFeatureNodes(feature) {
    return document.querySelectorAll(feature.cssSelector);
  }
}

export default Zephr;
