import Zephr from './zephr';
import { createEvent, log } from './utils';

/**
 * Creates a new instance of the Zephr Browser library.
 *
 * @param {string} cdnApi - The Zephr CDN API base url.
 * @param {Function} [fetcher] - The fetch function to be used for making requests. If not provided, the default fetch is used.
 *
 * @returns {Zephr} A new Zephr instance.
 */
const createInstance = (cdnApi, fetcher) => {
  return new Zephr(cdnApi, fetcher);
};

/**
 * Runs Zephr feature decisions on the current page.
 *
 * @param {Object|string} [options] - The options for the run function. If a string is provided, it is treated as the cdnApi. If an object is provided, it should contain the following properties:
 * @param {string} [options.cdnApi] - The Zephr CDN API base url.
 * @param {string} [options.jwt] - The JWT token for authentication.
 * @param {Object} [options.customData] - Additional string key/values to be used in decisions.
 * @param {boolean} [options.debug] - Enables debug logging.
 * @param {Function} [options.fetcher] - The fetch function to be used for making requests. If not provided, the default fetch is used.
 * @param {boolean} [options.automaticPolyfills=true] - Enables automatic polyfills for unsupported browser features.
 *
 * @returns {Zephr} The Zephr instance.
 *
 * @throws {Error} If there is an error in fetching live features or decisions.
 */
const run = async (options) => {
  const isDebugMode = (options && options.debug) || (localStorage && localStorage.getItem('zephrBrowserDebug'));
  let cdnApi = '';
  let decisionOptions = {
    automaticPolyfills: true,
  };

  if (typeof options === 'string') {
    cdnApi = options;
  } else if (typeof options === 'object') {
    decisionOptions = { ...decisionOptions, ...options };
    cdnApi = options.cdnApi || '';
  }

  const zephrInstance = createInstance(cdnApi, options?.fetcher);
  const response = await zephrInstance.fetchLiveFeatures();
  let liveFeatures;
  let datalayerOutcomesConfig;
  // zephr/features api response type changed to an object since compatibility version 3.
  // Customer requires compatibility version >= 3 to access data layer outcomes.
  // This handles the new and old response types.
  if (Array.isArray(response)) {
    liveFeatures = response
  } else {
    liveFeatures = response.features
    datalayerOutcomesConfig = response.datalayerOutcomesConfig
  }
  log(isDebugMode, 'Live Features:', liveFeatures);

  const featuresFound = zephrInstance.findFeatures(liveFeatures);
  if (!featuresFound.length) {
    log(isDebugMode, 'No features found on page.');
  } else {
    log(isDebugMode, 'Features on page:', featuresFound);


    const decisions = await zephrInstance.fetchDecisions(featuresFound, decisionOptions);
    log(isDebugMode, 'Decisions:', decisions);

    const executionStart = performance.now();

    await zephrInstance.executeDecisions(featuresFound, decisions, datalayerOutcomesConfig, decisionOptions);

    log(isDebugMode, `Decision execution finished: took ${performance.now() - executionStart} ms`);
  }

  document.dispatchEvent(createEvent('zephr.browserDecisionsFinished'));

  return zephrInstance;
};

export {
  createInstance,
  run,
};
