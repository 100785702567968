/**
 * @typedef {Object} FeatureDecisionFlags
 * @property {boolean} LOCALE_AWARE_TRUNCATION
 */

/**
 * @type {FeatureDecisionFlags} DEFAULT_FLAGS
 */
const DEFAULT_FLAGS = Object.freeze({
  LOCALE_AWARE_TRUNCATION: false
});

/**
 * Checks if Intl.Segmenter is currently supported.
 *
 * @returns {boolean}
 */
const isIntlSegmenterSupported = () => {
  return Intl && 'Segmenter' in Intl;
}

const createEvent = (eventName) => {
  let event;

  if (typeof(Event) === 'function') {
      event = new Event(eventName);
  } else {
      event = document.createEvent('Event');
      event.initEvent(eventName, true, true);
  }

  return event;
};

/**
 * Fetches a script from a given URL and executes it.
 *
 * @param {string} url - The URL of the script to fetch and execute.
 * @returns {Promise<void>} A Promise that resolves when the script has been fetched and executed.
 */
const executeScript = async (url) => {
  try {
    await fetch(url)
      .then((res) => res.text())
      .then((script) => new Function(script)());
  } catch (error) {
    console.error(`Error fetching and executing script from ${url}:`, error);
  }
}

const log = (isDebugMode, ...args) => {
  if (isDebugMode) {
    console.log("[Zephr]", ...args) // FSM-141
  }
}

export {
  createEvent,
  DEFAULT_FLAGS,
  executeScript,
  isIntlSegmenterSupported,
  log,
};
