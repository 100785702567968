import { applyTruncateStyle } from './truncate';

/**
 * @typedef {Object} TruncateOutcome
 * @property {number} truncateLength - The number of words to truncate.
 * @property {TruncateStyle} style - The style to apply after truncation.
 */

/**
 * Truncates the given HTML node based on the specified outcome.
 *
 * @param {HTMLElement} node - The HTML element to be truncated.
 * @param {TruncateOutcome} outcome - The outcome object containing the truncate length and style.
 * @returns {HTMLElement} The HTML element after truncation and style application.
 */
export function truncateNodeLegacy(node, outcome) {
  const nodeString = node.outerHTML;
  const maxLength = outcome.truncateLength * 2;
  const splitBody = nodeString.trim().split(/\s+/);

  if (outcome.truncateLength >= splitBody.length) return node;

  let doTruncateAt = 0;
  let isTag = false;
  let wordTotal = 0;
  let truncatePoint = 0;

  for (const word of splitBody) {
    truncatePoint++;
    const wasTag = isTag;
    const openIndex = word.lastIndexOf('<');
    const closeIndex = word.lastIndexOf('>');

    if (openIndex >= 0) isTag = true;
    if (closeIndex >= 0) isTag = false;
    if (openIndex !== -1 && closeIndex !== -1 && openIndex > closeIndex) {
      isTag = true;
    }
    if ((wasTag && closeIndex === word.length - 1) && !hasTagsContainingWord(word) || openIndex === 0 && closeIndex === word.length - 1) {
      isTag = false;
      continue;
    }
    if (isTag) continue;
    if (doTruncateAt > 0) {
      break;
    }

    wordTotal++;

    if ((wordTotal >= outcome.truncateLength && word.includes('.')) || wordTotal === maxLength) {
      doTruncateAt = truncatePoint;
    }
  }

  if (truncatePoint === splitBody.length) return node;

  truncatePoint = doTruncateAt > 0 ? doTruncateAt : truncatePoint;

  let truncatedBody = splitBody.slice(0, truncatePoint).join(' ');

  if (wordTotal === maxLength) truncatedBody += '...\n';

  const parsedHtml = new DOMParser().parseFromString(truncatedBody, 'text/html');
  const truncatedHtml = parsedHtml.body.children[0];
  return applyTruncateStyle(truncatedHtml, outcome.style);
}

function hasTagsContainingWord(word) {
  return word.search(">[^<>]+<") !== -1;
}

export default truncateNodeLegacy;
