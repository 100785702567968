import { applyTruncateStyle, generateTextTokens, TextTokenType } from './truncate';

const IGNORED_TAG_NAMES = Object.freeze(['script', 'style', 'noscript', 'template', 'iframe', 'svg', 'canvas']);

/**
 * Truncates the given HTML node based on the specified outcome, taking the document's locale into consideration.
 *
 * @param {HTMLElement} node - The HTML element to be truncated.
 * @param {TruncateOutcome} outcome - The outcome object containing the truncate length and style.
 * @returns {HTMLElement} The HTML element after truncation and style application.
 */
export function truncateNodeLocaleAware(node, outcome) {
  const locale = document.documentElement?.lang || 'en';
  const maxLength = outcome.truncateLength * 2;
  const textTokens = generateTextTokens(node, locale);

  if (outcome.truncateLength >= textTokens.length) return node;

  let doTruncateAt = 0;
  let isTag = false;
  let isIgnoredBlock = false;
  let wordTotal = 0;
  let truncatePoint = 0;

  for (const textToken of textTokens) {
    truncatePoint++;

    if (textToken.type === TextTokenType.WHITESPACE && !textToken.isSentenceEnd) {
      continue;
    }

    const trimmedText = textToken.text.trim();

    if (isIgnoredBlock && trimmedText.indexOf('</') >= 0 && shouldIgnoreTag(trimmedText)) {
      isIgnoredBlock = false;
    } else if (trimmedText.indexOf('<') >= 0 && shouldIgnoreTag(trimmedText)) {
      isIgnoredBlock = true;
    }

    if (isIgnoredBlock) {
      continue;
    }

    const wasTag = isTag;
    const openIndex = trimmedText.lastIndexOf('<');
    const closeIndex = trimmedText.lastIndexOf('>');

    if (openIndex >= 0) isTag = true;
    if (closeIndex >= 0) isTag = false;
    if (openIndex !== -1 && closeIndex !== -1 && openIndex > closeIndex) {
      isTag = true;
    }
    if ((wasTag && closeIndex === trimmedText.length - 1) || (openIndex === 0 && closeIndex === trimmedText.length - 1)) {
      isTag = false
      continue;
    }
    if (isTag) {
      continue;
    }

    if (doTruncateAt > 0 && textToken.type === TextTokenType.WORD) {
      break;
    }

    // Ignore words that are just punctuation
    if (textToken.type !== TextTokenType.WORD && !textToken.isSentenceEnd) {
      continue
    }

    // Only count words...
    if (textToken.type === TextTokenType.WORD) {
      wordTotal++
    }

    if (wordTotal === maxLength || (wordTotal >= outcome.truncateLength && textToken.isSentenceEnd)) {
      doTruncateAt = truncatePoint
    }
  }

  if (truncatePoint === textTokens.length) return node;

  truncatePoint = doTruncateAt > 0 ? doTruncateAt : truncatePoint;

  let truncatedBody = textTokens
    .slice(0, truncatePoint)
    .map((textToken) => textToken.text)
    .join('');

  if (wordTotal === maxLength) truncatedBody += '...';

  const parsedHtml = new DOMParser().parseFromString(truncatedBody, 'text/html');
  const truncatedHtml = parsedHtml.body.children[0];
  return applyTruncateStyle(truncatedHtml, outcome.style);
}

/**
 * Does this text token contain an ignored tag?
 *
 * @param {string} text
 * @returns {boolean}
 */
function shouldIgnoreTag(text) {
  return IGNORED_TAG_NAMES.some((tag) => {
    const hasStartTag = text.indexOf(`<${tag}`) >= 0;
    const hasEndTag = text.indexOf(`</${tag}`) >= 0;
    return hasStartTag ? !hasEndTag : hasEndTag;
  });
}

export default truncateNodeLocaleAware;
