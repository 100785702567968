/**
* @typedef {string} TruncateStyle
* @enum {'fadeout', 'linebreak', 'nostyle'}
*/

/**
 * Applies the specified style to the truncated HTML.
 *
 * @param {HTMLElement} truncatedHtml - The HTML element that has been truncated.
 * @param {TruncateStyle} style - The truncate style to apply.
 * @returns {HTMLElement} The HTML element with the applied style.
 */
export function applyTruncateStyle(truncatedHtml, style) {
  let outputHtml;

  switch (style) {
    case 'fadeout':
      const fadeoutDiv = document.createElement('div');
      fadeoutDiv.style.position = 'absolute';
      fadeoutDiv.style.height = '100%';
      fadeoutDiv.style.width = '100%';
      fadeoutDiv.style.bottom = '0';
      fadeoutDiv.style.backgroundImage = 'linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%)';
      truncatedHtml.appendChild(fadeoutDiv);

      outputHtml = document.createElement('div');
      outputHtml.style.position = 'relative';
      outputHtml.appendChild(truncatedHtml);
      break;
    case 'linebreak':
      const linebreakHr = document.createElement('hr');
      linebreakHr.style.border = '1px solid #ebebeb';
      truncatedHtml.appendChild(linebreakHr);
      outputHtml = truncatedHtml;
      break;
    case 'nostyle':
    default:
      outputHtml = truncatedHtml;
  }

  return outputHtml;
}

export default applyTruncateStyle;
